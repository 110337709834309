<template>
  <div class="consult">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('scheme')">
        <img
          v-lazy="require('@/assets/consult/banner.png')"
          class="banner-img"
        />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--政企解决方案-->
    <div class="scheme" ref="scheme">
      <div class="title-box reveal">
        <div class="title1">医疗机构·智慧门诊解决方案</div>
        <div class="title3">
          通过专业临床量表，根据来访者的心理特点，进行包括人格、心理健康、情绪、自我评价等方面的专业<br />
          &nbsp;测评，为每位来访者建立专门的心理档案，免购买测评设备，快速构建测评云服务，打造私域平台。
        </div>
      </div>
      <div class="scheme-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/consult/scheme1.png')"
            class="box-img"
          />
          <div class="box-title">临床标准化量表数据模型</div>
          <div class="box-intro">
            严格按照中华医学会所推出的量表进行了标准化数据模型编写，量表的信度和效度都经过了专家的严格校正，充分保证测评结果的高度准确性。
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/consult/scheme2.png')"
            class="box-img"
          />
          <div class="box-title">22类,100多个量表,种类齐全</div>
          <div class="box-intro">
            涵盖心理健康、人格测验、职业评定、智力测验、婚姻家庭等22类，100多个量表，涵盖临床上主要涉及的精神分裂、情感障碍、酒依赖、睡眠障碍、性心理、心理治疗、心理咨询与评估等问题。
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/consult/scheme3.png')"
            class="box-img"
          />
          <div class="box-title">
            临床应用量表电子化，为诊疗 提供高效、专业支持
          </div>
          <div class="box-intro">
            上百种常用专业临床心理量表电子化、数据化，免购专用测试设备，扫码测试，挂号、测评同步进行，快速、高效出具专业报告，为每位来访者建立专门的心理档案，提高门诊诊疗效率和服务满意度，构建私域云平台。
          </div>
        </div>
      </div>
    </div>
    <!--多场景应用-->
    <div class="use" ref="use">
      <div class="title-box reveal">
        <div class="title1">医疗机构·智慧门诊应用场景</div>
        <div class="title3" >
          通过专业临床量表电子化，扫码测评，无纸化测评，与患者云端对话，并根据来访者的心理特点，进行包括人格、心理健<br />
          &nbsp;康、情绪、自我评价等方面的专业测评，为每位来访者建立专门的心理档案，为来访者提供高效的测量服务和诊断效率。
        </div>
      </div>
      <div class="use-box reveal box-bg1">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">01</span>
            心理门诊扫码云端测评
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >适用于各级心理门诊远程心理测评，挂号、测评一体化，提升服务效率，门诊降本增效
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >量表涵盖门诊常用的心理健康、婚姻家庭、人格、情绪、自我评价等多方面的临床专业测评
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >为每位病患建立专门的心理档案，为有效的缓解和改善患者心理疾病提供科学参考依据
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
      <div class="use-box reveal box-bg2">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">02</span>
            心理咨询机构测评服务
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >主要面向遇到心理困扰的正常人群以及各类型的轻度心理障碍者
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >掌握每个来访者的心理健康状况，动态的监测来访者心理变化情况
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >心理危机预警，防止极端情况发生，有效的做到预防、预警和干预
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
      <div class="use-box reveal box-bg3">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">03</span>
            医务工作者自测服务
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >医务工作具有高风险、高强度、高负荷等职业特点，持续高度紧张工作容易危害心理健康
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >医务工作者心理自测，提高临床医疗质量和工作效率，确保医疗工作的良性运转
          </div>
          <div class="box-intro reveal2">
            <i
              class="el-icon-success"
              style="color: #536cfe; margin-right: 10px"
            ></i
            >对病人的心理压力和心身疾病的识别和治疗也有一定的意义
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
    </div>
    <!--体验测评-->
    <div class="eval reveal" ref="eval">
      <div class="eval-box">
        <img v-lazy="require('@/assets/consult/eval.png')" class="eval-img" />
      </div>
    </div>
    <!--权威测评量表-->
    <div class="projects" ref="projects">
      <div class="title-box reveal">
        <div class="title1">医疗机构·智慧门诊心理测评服务</div>
        <div class="title2">
          提高门诊诊疗效率和服务满意度，构建门诊私域云平台。
        </div>
      </div>
      <div class="project-box reveal">
        <div style="margin-bottom: 70px">
          <div class="project-line reveal"></div>
          <div class="project-title reveal1">
            国际权威量表，专业临床应用
          </div>
          <div class="project-intro reveal2">
            采用国际权威通用的量表，常模本土化，结合国内大量临床实践修订，涉及到门诊、医疗机构的量表有50多个，是评测来访者心理全方位指标的有效工具，也是专业心理咨询机构、三甲医院常用的诊断标准。
          </div>
        </div>
        <div>
          <div class="project-line reveal"></div>
          <div class="project-title reveal1">
            告别纸质测评，云端扫码、高效便捷
          </div>
          <div class="project-intro reveal2">
            为每个门诊搭建专门测量平台，微信扫码测量，电脑端即时查看打印评测结果，提高诊疗效率
            患者无需达到门诊测评，以测评为诊疗入口，为用户建立电子档案，构建门诊私域服务云平台
          </div>
        </div>
      </div>
    </div>
    <!--评测报告解读-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">面向来访者输出专业测评报告</div>
        <div class="title2">
          生成多维度专业心理分析报告，测评、挂号一体化
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1">
          <div class="box-title title-bg1">
            <div style="background: #fafbff">身体健康情况评估</div>
          </div>
          <div class="box-intro">
            12大方面定位你的身体各项状况，精细化分析症状表现，快速筛查潜在健康风险。
          </div>
          <img
            v-lazy="require('@/assets/consult/report-img1.png')"
            class="box-img1"
          />
        </div>
        <div class="box reveal2">
          <div class="box-title title-bg2">
            <div style="background: #fafbff">心理健康情况评估</div>
          </div>
          <div class="box-intro">
            从6大方面评估你的精神心理状态，详细解析对应的心理倾向，准确筛查潜在精神层面风险。
          </div>
          <img
            v-lazy="require('@/assets/consult/report-img2.png')"
            class="box-img2"
          />
        </div>
        <div class="box reveal3">
          <div class="box-title title-bg3">
            <div style="background: #fafbff">睡眠质量分析评估</div>
          </div>
          <div class="box-intro">
            从你的睡眠质量、夜间习惯、个人主观思想三方面分析睡眠等级，寻找有效方法。
          </div>
          <img
            v-lazy="require('@/assets/consult/report-img3.png')"
            class="box-img3"
          />
        </div>
        <div class="box reveal4">
          <div class="box-img4">更多专业报告，持续输出</div>
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">填写信息，立即开通体验</div>
      </div>
      <img
          v-lazy="require('@/assets/org/test.jpg')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "consult",
  data() {
    return {
      tabs: [
        {
          name: "方案介绍",
          id: "scheme",
          hover: false,
        },
        {
          name: "适用场景",
          id: "use",
          hover: false,
        },
        {
          name: "专业性说明",
          id: "eval",
          hover: false,
        },
        {
          name: "权威测评量表",
          id: "projects",
          hover: false,
        },
        {
          name: "测评结果展示",
          id: "report",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function() {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function(name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.consult {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
    .title3 {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/consult/banner-bg.png");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .scheme {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .scheme-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 374px;
        height: 490px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        padding: 20px 28px;
        .box-img {
          width: 318px;
          height: 150px;
          margin-bottom: 36px;
        }
        .box-title {
          color: $title-color;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .box-intro {
          color: $intro-color;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .box:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .use {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .use-box {
      width: $width;
      height: 510px;
      background-size: $width 510px;
      background-repeat: no-repeat;
      .box-main {
        background: rgba(255, 255, 255, 0.83);
        padding: 63px 0 0 72px;

        .title-num {
          font-size: 90px;
          font-family: Arial;
          line-height: 30px;
          color: $title-color;
          font-style: Italic;
        }
        .box-title {
          font-size: 40px;
          color: $title-color;
          line-height: 30px;
          margin-bottom: 48px;
        }
        .box-intro {
          font-size: 22px;
          color: #666;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .box-btn {
          width: 200px;
          height: 60px;
          line-height: 60px;
          background: #536cfe;
          border-radius: 5px;
          text-align: center;
          color: #fff;
          font-size: 24px;
          margin-top: 40px;
          transition: all 0.2s;
        }
        .box-btn:hover {
          transform: translateY(10px);
        }
      }
    }
    .box-bg1 {
      background: url("~@/assets/consult/use-bg1.png");
      padding: 24px 0 0 59px;
      .box-main {
        width: 1010px;
        height: 458px;
      }
    }
    .box-bg2 {
      background: url("~@/assets/consult/use-bg2.png");
      padding: 24px 0 0 335px;
      .box-main {
        width: 816px;
        height: 458px;
      }
    }
    .box-bg3 {
      background: url("~@/assets/consult/use-bg3.png");
      padding: 24px 0 0 64px;
      .box-main {
        width: 1021px;
        height: 458px;
      }
    }
    .use-box:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }

  .eval {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .eval-box {
      @include flex($justify: center);
      width: 100%;
      background: #536cfe;
      overflow: hidden;

      .eval-img {
        width: 1920px;
        height: 300px;
      }
    }
  }

  .projects {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    background: #f7f7f7;
    .title-box {
      margin-bottom: 60px;
    }
    .project-box {
      width: $width;
      height: 520px;
      padding: 50px 72px 0 92px;
      background: url("~@/assets/consult/project-bg.png");
      background-size: $width 520px;
      .project-line {
        width: 80px;
        height: 4px;
        background: #ffffff;
        margin-bottom: 30px;
      }
      .project-title {
        font-size: 40px;
        color: #fff;
        line-height: 30px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      .project-intro {
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    background: #f7f7f7;
    padding-top: 100px;
    padding-bottom: 90px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column);
        width: 280px;
        height: 474px;
        border-radius: 4px;
        background: #fff;
        padding: 15px 20px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 117px;
          font-size: 30px;
          color: #596df8;
          font-weight: bold;
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 99px 101px !important;
          padding-top: 80px;
        }
        .title-bg1 {
          background-image: url("~@/assets/consult/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/consult/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/consult/title-bg3.png");
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 26px;
          margin-top: 24px;
        }
        .box-img1 {
          width: 170px;
          height: 163px;
          margin-top: 45px;
        }
        .box-img2 {
          width: 181px;
          height: 160px;
          margin-top: 52px;
        }
        .box-img3 {
          width: 147px;
          height: 170px;
        }
        .box-img4 {
          color: #929292;
          font-size: 20px;
          padding-top: 205px;
          line-height: 26px;
          text-align: center;
        }
      }
      .box:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }
  }
}
</style>
